import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import Link from '../link/Link';

const styles = () => ({
  card: {
    minHeight: 370
  }
});

const LinkCard = ({ classes, image, title, to }) => (
  <Link to={to}>
    <CardActionArea>
      <Card raised className={classes.card}>
        <CardMedia
          component="img"
          image={image.url}
          title={image.alt}
          alt={image.alt}
        />
        <CardContent>
          <Typography variant="h5" component="h4" align="center">
            {title}
          </Typography>
        </CardContent>
      </Card>
    </CardActionArea>
  </Link>
);

LinkCard.propTypes = {
  classes: PropTypes.shape({ card: PropTypes.string }).isRequired,
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    copyright: PropTypes.string
  }).isRequired,
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired
};

export default withStyles(styles)(LinkCard);
