import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const SectionHeading = ({ title, subtitle }) => (
  <React.Fragment>
    <Typography variant="h4" component="h2" align="center" gutterBottom>
      {title}
    </Typography>
    <Typography variant="h5" component="h3" align="center" gutterBottom>
      {subtitle}
    </Typography>
  </React.Fragment>
);

SectionHeading.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string
};

SectionHeading.defaultProps = {
  subtitle: null
};

export default SectionHeading;
