import uuid from 'uuid';
import React from 'react';
import PropTypes from 'prop-types';
import { navigate, graphql } from 'gatsby';
import Grid from '@material-ui/core/Grid';

import Page from '../layout/Page';
import Container from '../layout/Container';
import Hero from '../components/hero/Hero';
import SectionHeading from '../components/section/SectionHeading';
import Cta from '../components/cta/Cta';

import LinkCard from '../components/cards/LinkCard';
import ProductCard from '../components/cards/ProductCard';

const Index = ({ data, location }) => {
  const { prismicHome } = data;
  if (!prismicHome) {
    if (typeof window !== 'undefined') {
      navigate('/');
    }
    console.error('Page has not been translated.');
    return null;
  }

  const { data: prismicData } = prismicHome;
  const {
    page_title,
    page_description,
    page_keywords,
    page_image,
    hero_title,
    hero_subtitle,
    hero_button,
    hero_image,
    about_title,
    about_subtitle,
    about_cards,
    products_title,
    products_subtitle,
    product_cards,
    cta_title,
    cta_subtitle,
    cta_button
  } = prismicData;

  return (
    <Page
      seo={{
        page_title,
        page_description,
        page_keywords,
        page_image,
        pathname: location.pathname
      }}
    >
      <Container size={50} isCenter>
        <Hero
          title={hero_title}
          subtitle={hero_subtitle}
          image={hero_image}
          button={hero_button[0]}
        />
      </Container>

      <Container isCenter>
        <SectionHeading title={about_title} subtitle={about_subtitle} />
      </Container>

      <Container size={50} isCenter>
        <Grid
          container
          item
          direction="row"
          justify="space-around"
          alignItems="stretch"
          spacing={1}
        >
          {about_cards &&
            about_cards.map(about_card => (
              <Grid item xs={12} sm={6} md={4} lg={2} key={uuid.v4()}>
                <LinkCard
                  image={about_card.image}
                  title={about_card.title}
                  to={about_card.link}
                />
              </Grid>
            ))}
        </Grid>
      </Container>

      <Container isCenter>
        <SectionHeading title={products_title} subtitle={products_subtitle} />
      </Container>

      <Container size={50} isCenter>
        <Grid
          container
          item
          direction="row"
          justify="space-around"
          alignItems="stretch"
          spacing={1}
        >
          {product_cards &&
            product_cards.map(product => (
              <Grid item xs={12} sm={6} md={3} key={uuid.v4()}>
                <ProductCard product={product} />
              </Grid>
            ))}
        </Grid>
      </Container>

      <Container
        size={50}
        background="url(/images/footer_background.png) bottom/cover no-repeat"
        style={{ marginTop: '-15%' }}
      />

      <Container background="#1976d2">
        <Cta title={cta_title} subtitle={cta_subtitle} button={cta_button[0]} />
      </Container>
    </Page>
  );
};

Index.propTypes = {
  data: PropTypes.shape({
    prismicHome: PropTypes.shape({
      data: PropTypes.shape().isRequired
    }).isRequired
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
};

export default Index;

export const query = graphql`
  query IndexQuery($locale: String!) {
    prismicHome(lang: { eq: $locale }) {
      lang
      data {
        page_title
        page_description
        page_keywords
        page_image {
          alt
          copyright
          url
        }
        hero_title
        hero_subtitle
        hero_button {
          title
          to
        }
        hero_image {
          alt
          copyright
          url
        }
        about_title
        about_subtitle
        about_cards {
          title
          image {
            alt
            copyright
            url
          }
          link
        }
        products_title
        products_subtitle
        product_cards {
          title
          image {
            alt
            copyright
            url
          }
          link
          description
          subtitle
        }
        cta_title
        cta_subtitle
        cta_button {
          title
          to
        }
      }
    }
  }
`;
