import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';

import Link from '../link/Link';

const ProductCard = ({ product }) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card raised style={{ minHeight: 536 }}>
      <Link to={product.link}>
        <CardActionArea>
          <CardMedia
            style={{ backgroundColor: '#f9f9f9' }}
            component="img"
            image={product.image.url}
            title={product.image.alt}
            alt={product.image.alt}
          />
        </CardActionArea>
      </Link>
      <CardContent>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item xs>
            <Typography
              variant="h5"
              component="h4"
              style={{ display: 'inline' }}
              gutterBottom
            >
              {product.title}
            </Typography>
          </Grid>
          <Grid item xs="auto">
            {product.description && (
              <IconButton
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>

        {product.subtitle && (
          <Typography variant="subtitle1" component="h5" gutterBottom>
            {product.subtitle}
          </Typography>
        )}

        {product.description && (
          <Collapse in={expanded}>
            <Typography variant="body1" component="p" gutterBottom>
              {product.description}
            </Typography>
          </Collapse>
        )}
      </CardContent>
    </Card>
  );
};

ProductCard.propTypes = {
  product: PropTypes.shape({
    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
      copyright: PropTypes.string
    }).isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    description: PropTypes.string,
    link: PropTypes.string.isRequired
  }).isRequired
};

export default ProductCard;
